@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .text-grad {
    @apply text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-yellow-500;
  }

  .text-grad-2 {
    @apply text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-green-300;
  }

  .text-grad-3 {
    @apply text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-blue-200;
  }

  .custom-btn {
    @apply px-4 py-2 rounded-xl font-semibold bg-gradient-to-r from-white to-white text-black hover:from-red-500 hover:to-yellow-500 hover:text-white cursor-pointer no-underline;
  }

  .custom-btn-2 {
    @apply px-4 py-2 rounded-xl font-semibold bg-gradient-to-r from-white to-white text-black hover:from-blue-500 hover:to-green-500 hover:text-white cursor-pointer no-underline;
  }

  .custom-btn-3 {
    @apply px-4 py-2 rounded-xl font-semibold bg-gradient-to-r from-white to-white text-black hover:from-indigo-500 hover:to-blue-400 hover:text-white cursor-pointer no-underline;
  }
}
@import url('https://fonts.googleapis.com/css?family=Poppins');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
